import React, { useEffect, useState } from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"
import HeroSlider from "../components/hero-slider/hero-slider"
import SearchBar from "../components/search-bar/search-bar"
import Services from "../components/services/services"
import Categories from "../components/categories/categories"
import TreatoCare from '../components/treatoCare/treatoCare'
import { graphql } from "gatsby"
import axios from  "axios"
import { URLS } from "../config/urls"
import Footer from "../components/footer/footer"

const IndexPage = (
  // {data:{allWpPost:{nodes:blogsData}}}
  ) => {
  const [popularService,setPopularServices] = useState([])
  const [popularCategories,setPopularCategories] = useState([])
  useEffect(()=>{
    axios.get(URLS.popularCategory()).then(({data})=>{
      setPopularCategories(data)
    })
    axios.get(URLS.popularServices()).then(({data})=>{
      setPopularServices(data)
    })
  },[])

  return(
  <Layout>
    <SEO title="Home" />
    <div style={{zIndex: "5 !important"}}>
      <HeroSlider/>
      <div style={{marginTop: "-30px"}}>
        <SearchBar redirect={true}/>
      </div>
    </div>
    <div className="uk-section">
      <div className="uk-container uk-container-medium">
        <h2 className="uk-text-center uk-text-primary"><span>Popular Categories</span></h2>
        <p className="uk-text-muted uk-text-center">
          We would like to draw your attention towards most popular categories on out platform.
        </p>
        <div className="mt4">
        <Categories list={popularCategories}/>
        </div>
      </div>
    </div>
    <div className="uk-section" style={{background: "linear-gradient(90deg, rgba(70,195,221,1) 0%, rgba(70,195,221,1) 35%, rgba(77,222,200,1) 100%)"}}>
      <div className="uk-container uk-container-medium">
        <h2 className="uk-text-center color-white"><span>Popular Services</span></h2>
        <p className="uk-text-muted uk-text-center color-white">
          We have curated a list of most booked services on Treato so that you can know what's the trend of the season.
        </p>
        <div className="mt4">

        <Services list={popularService}/>
        </div>
      </div>
    </div>
    <div className="uk-section">
      <div className="uk-container uk-container-medium">
        <h2 className="uk-heading-line uk-text-center uk-text-primary"><span>Treato Care</span></h2>
        <div className="padding-50"/>
        {/* <TreatoCare blogs={blogsData}/> */}
      </div>
    </div>
    <Footer/>
  </Layout>
)}


// export const query = graphql`
// query {
//   allWpPost {
//     nodes {
//       id
//       link
//       featuredImage {
//         node {
//           mediaItemUrl
//         }
//       }
//       title
//       excerpt
//     }
//   }
// }
// `

export default IndexPage
