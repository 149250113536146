import React, { useContext } from "react"
import Card from "./card/card"
import service from "../../images/hair-style.jpg"
import { navigate } from "gatsby"
import { searchContext } from "../../context/searchContext/searchContext"
import { base } from "../../config/urls"

const Services = ({ list }) => {
  const searchCtx = useContext(searchContext)
  if (list.length)
    return (
      <div className="uk-grid-medium uk-grid-match uk-child-width-1-2 uk-child-width-1-4@s uk-text-center" data-uk-grid>
        {list.map(({ service }) =>
                  <div>
                    <div className="uk-card uk-card-default uk-card-small uk-card-body cursor-pointer"
                       onClick={() => {
                         searchCtx.setLocation(null)
                         searchCtx.setSearchString(service.name)
                         searchCtx.searchTextBaseSalon(service.name)
                         navigate("/listing")
                       }}>
                      <div className="uk-text-lead mb2">{service.name}</div>
                      <div className="uk-text-meta" style={{maxWidth: "100%", overflow: "hidden", textOverflow: "ellipses"}}>{service.description.slice(0, 50)}</div>
                  </div>
                    </div>)}
        {/* <div data-uk-slider>
          <div className="uk-position-relative">
            <div className="uk-slider-container uk-position-relative uk-visible-toggle uk-light" tabindex="-1">

              <ul className="uk-slider-items uk-child-width-1-2@s uk-child-width-1-4@m">
                {list.map(({ service }) => <li>
                  <div className="uk-card uk-card-default"
                       onClick={() => {
                         searchCtx.setLocation(null)
                         searchCtx.setSearchString(service.name)
                         searchCtx.searchTextBaseSalon(service.name)
                         navigate("/listing")
                       }}>
                    <div className="uk-card-media-top">
                      <img src={service.category && service.category["pic"]} alt={service.name}/>
                    </div>
                    <div className="uk-card-body">
                      <h3 className="uk-card-title">{service.name}</h3>
                      <p>{service.description}</p>
                    </div>
                  </div>
                </li>)}
              </ul>

            </div>
            <a className="uk-position-center-left-out uk-position-small uk-hidden-hover" href="#"
               data-uk-slidenav-previous
               data-uk-slider-item="previous"/>
            <a className="uk-position-center-right-out uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-next
               data-uk-slider-item="next"/>
          </div>

          <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>

        </div> */}
      </div>
    )
  return null
}

export default Services
