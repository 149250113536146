import React, { useContext, useEffect } from "react"
import { searchContext } from "../../context/searchContext/searchContext"
import pic from "../../images/hair-style.jpg"
const Categories = (
  // {list}
) => {
  const list = [1,2,3,4,5];
  const searchCtx = useContext(searchContext)
  return (<>

    <div data-uk-slider>
          <div className="uk-position-relative">
            <div className="uk-slider-container uk-position-relative uk-visible-toggle uk-light" tabindex="-1">
              <ul className="uk-slider-items uk-grid-medium uk-grid-match uk-child-width-1-2@s uk-child-width-1-4@m">
                {list.map(() => <li>
                  <div className="uk-card uk-card-default uk-card-hover mb4 cursor-pointer">
                    <div className="uk-card-media-top">
                      <img src={pic} alt={'src/images/hair-style.jpg'}/>
                    </div>
                    <div className="pa3">
                      <div className="uk-text-lead uk-text-bold" style={{color: "#999"}}>{'Category'}</div>
                    </div>
                  </div>
                </li>)}
              </ul>

            </div>
            <a className="uk-position-center-left-out uk-position-small uk-hidden-hover" href="#"
               data-uk-slidenav-previous
               data-uk-slider-item="previous"/>
            <a className="uk-position-center-right-out uk-position-small uk-hidden-hover" href="#" data-uk-slidenav-next
               data-uk-slider-item="next"/>
          </div>

          <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>

        </div>
    </>
  )
}

export default Categories


// <div className="uk-grid-small uk-child-width-1-2 uk-child-width-1-4@s uk-text-center" data-uk-grid>
//       {list.map(({ category }) =>
//           <div className="uk-card uk-card-default uk-card-small uk-card-body"
//                onClick={() => {
//                  searchCtx.searchSubCategoryBaseSalon(category.id)
//                  navigate("/listing")
//                }}
//           >
//             <h4>{category.name}</h4>
//             <img src={category.pic} alt=""/>
//           </div>
//       )}
//     </div>
