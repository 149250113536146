import React from "react"

const HeroSlider = () => {
  return (
    <div
      className="uk-position-relative uk-visible-toggle uk-light"
      tabIndex="-1"
      data-uk-slideshow="ratio: 7:2; animation: push; min-height: 300; autoplay: true;"
    >
      <ul className="uk-slideshow-items">
        <li style={{background: "transparent linear-gradient(73deg, #D41959 0%, #FF6F00 100%) 0% 0% no-repeat padding-box"}}>
          <div className="uk-position-center-left uk-margin-large-left uk-text-left uk-light">
            <h1 className="uk-heading-medium">
              Experience the <br /> Hidden Corners of Beauty
            </h1>
          </div>
        </li>
        <li style={{background: "linear-gradient(90deg, rgba(70,195,221,1) 0%, rgba(70,195,221,1) 35%, rgba(77,222,200,1) 100%)"}}>
          <div className="uk-position-center-left uk-margin-large-left uk-text-left uk-light">
            <h1 className="uk-heading-medium">
              Best Salons <br /> Curated for You
            </h1>
          </div>
        </li>
      </ul>

      <a
        className="uk-position-center-left uk-position-small uk-hidden-hover"
        href="#"
        data-uk-slidenav-previous
        data-uk-slideshow-item="previous"
      ></a>
      <a
        className="uk-position-center-right uk-position-small uk-hidden-hover"
        href="#"
        data-uk-slidenav-next
        data-uk-slideshow-item="next"
      ></a>
    </div>
  )
}

export default HeroSlider;